import { usePrintRasnLpnLabelMutation } from 'api/rasn'
import { dispatch } from 'api/redux'
import { RasnRlpnDetailsPanel } from 'components/left-detail-panel'
import { MainPanel } from 'components/main-panel'
import { showSnackbar } from 'components/snackbar'
import { useMemo, useRef, useState } from 'react'
import {
  type LabelType,
  type RASN,
  type PrintLabelResponse,
  type CountryOfOrigin,
  type CreateUnitBySkuRequest
} from 'types'
import { useApiHandler, type ApiHandlerContext } from 'utils/useApiHandler'
import { v4 as uuidv4 } from 'uuid'

import './ScanUnit.css'

import { ActionButtons } from './components/ActionButtons'
import { ManualEntry } from './components/ManualEntry'
import { UpcEntry } from './components/UpcEntry'

interface ScanUnitProps {
  goodsHolder: string | undefined
  rasnData: RASN
  processedQuantity: number
  createUnitContext: ApiHandlerContext<string>
  createUnitBySkuContext: ApiHandlerContext<CreateUnitBySkuRequest>
  finishRasnContext: () => void
  handlePauseCarton: () => void
  closeGoodsHolderContext: ApiHandlerContext<string>
  showBlindIlpn: boolean
  isManualInput: boolean
  setIsManualInput: (isManualInput: boolean) => void
}

export const ScanUnit = ({
  goodsHolder,
  rasnData,
  processedQuantity,
  createUnitContext,
  createUnitBySkuContext,
  finishRasnContext,
  handlePauseCarton,
  closeGoodsHolderContext,
  showBlindIlpn,
  isManualInput,
  setIsManualInput
}: ScanUnitProps) => {
  const [error, setError] = useState('')
  const [upcBarcode, setUpcBarcode] = useState('')
  const [manualStyleColor, setManualStyleColor] = useState('')
  const [manualSize, setManualSize] = useState('')
  const styleColorRef = useRef<HTMLInputElement>(null)
  const [printILPNLabel] = usePrintRasnLpnLabelMutation()

  const printLabelContext = useApiHandler({
    apiFunction: async (labelType: LabelType) => await printILPNLabel({ rasnNumber: rasnData.number, labelType }).unwrap(),
    successAction: (response: PrintLabelResponse) => {
      const message = response.status === 'OK' ? 'Successfully printed label' : 'Failed to print label'
      const status = response.status === 'OK' ? 'success' : 'error'
      dispatch(showSnackbar(message, status))
    },
    failureAction: () => {
      dispatch(showSnackbar('Failed to print label', 'error'))
    }
  })

  // Automatic triggering of create unit by SKU when all fields are filled
  const handleConfirmManual = (country: CountryOfOrigin | undefined) => {
    setUnitId()
    createUnitBySkuContext.handleApiCall({ sku: manualStyleColor + '-' + manualSize, countryOfOrigin: country?.countryCode, lpn: null })
      .then()
      .catch(err => {
        if (err.status !== 404) throw err // this case should never be reached, if so it's a bug so throw
        dispatch(showSnackbar(`Unknown SKU ${manualStyleColor + '-' + manualSize}`, 'error'))
      })
  }

  const handleConfirmUPC = () => {
    if (error === '' && upcBarcode.length > 0 && !createUnitContext.isLoading) {
      setUnitId()
      createUnitContext.handleApiCall(upcBarcode)
        .then()
        .catch(err => {
          if (err.status !== 404) throw err // this case should never be reached, if so it's a bug so throw
          dispatch(showSnackbar(`Unknown UPC ${upcBarcode}`, 'error'))
        })
      setUpcBarcode('')
    }
  }

  const switchBetweenManualAndUpcScan = () => {
    setIsManualInput(!isManualInput)
    if (isManualInput) {
      styleColorRef.current?.focus()
    }
    setError('')
  }

  const renderManualInput = useMemo(
    () => (
            <ManualEntry styleColor={manualStyleColor}
                         size={manualSize}
                         onStyleColorChange={setManualStyleColor}
                         onSizeChange={setManualSize}
                         styleColorRef={styleColorRef}
                         confirm={handleConfirmManual}
                         isLoading={createUnitBySkuContext.isLoading}
            />
    ),
    [manualStyleColor, manualSize]
  )
  const renderScanInput = useMemo(
    () => (
            <UpcEntry upcBarcode={upcBarcode}
                      error={error}
                      setError={setError}
                      onUpcChange={setUpcBarcode}
                      onConfirm={handleConfirmUPC}
            />
    ),
    [upcBarcode, error]
  )

  const leftPanelContent =
      <RasnRlpnDetailsPanel goodsHolder={goodsHolder}
                            rasnData={rasnData}
                            processedQuantity={processedQuantity}
                            finishRasnContext={finishRasnContext}
                            closeGoodsHolderContext={closeGoodsHolderContext}
                            handlePauseCarton={handlePauseCarton}
      />

  const mainPanelContent =
      <div style={{ display: '-webkit-box' }}>
        <ActionButtons isManualInput={isManualInput} onSwapScreens={switchBetweenManualAndUpcScan} showBlindIlpn={showBlindIlpn}
                       printLabelContext={printLabelContext}/>
        <div style={{ width: '900px' }} className={'m-auto scanTitle'}>
          {isManualInput
            ? renderManualInput
            : renderScanInput
          }
        </div>
      </div>

  return <MainPanel leftPanelContent={leftPanelContent} mainPanelContent={mainPanelContent} />
}

const setUnitId = () => {
  // Backend requires a 12 byte hexademical ID, just like RF ID
  localStorage.setItem('unitId', uuidv4().replace(/-/g, '').substring(0, 24))
}
